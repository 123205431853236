.b-koios, .b-koios:before {
  background: $gradient-koios;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#1abc9c', GradientType=1 );
}

.button-container {
  display: block;
  text-align: center;
  .button:after {
    background: white;
  }
  .button {
    display: inline-block;
    position: relative;
    border-radius: 11px;
    text-decoration: none;
    padding: .5em 2.5em;
    margin: 1em 0;
    transition: all .1s;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
    box-shadow: 0px 0px 21px -10px rgba(110,110,110,0.5);
    @media (max-width: $mobile) {
      font-size: 0.9rem;
    }
  }
  .button:hover {
    box-shadow: 0px 0px 21px -10px rgba(110,110,110,1);
  }
  .button:hover:after {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .button:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    border-radius: 11px;
    transition: all .1s ease;
  }
  .button:after {
    content: '';
    display: block;
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 2px;
    z-index: -1;
    border-radius: 8px;
    transition: all .1s ease;
  }
}

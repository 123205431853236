.header {
  width: 100%;
  padding: 1em 1.5rem;
  align-items: flex-start;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: background-color .25s ease;

  &--white {
    background-color: white;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1280px;

    img {
      width: 118px;
      height: 60px;
    }

    .link-holder {
      display: flex;
      flex-flow: row;
      align-items: center;

      &__link {
        font-family: $font-family-headings;
        text-decoration: none;
        margin-left: $spacing-2x;
        color: $color-offwhite;
        font-weight: 400;
        font-size: 16px;
        cursor: pointer;
        border-bottom: 1px solid transparent;
        transition: color .25s ease, border-bottom .25s ease;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          border-bottom: 1px solid $color-offwhite;
        }

        @include for-size($tablet) {
          margin-left: $spacing-4x;
        }

        &--icon {
          height: 1em;
          width: 1em;
          fill: $color-offwhite;
          margin-bottom: -1px;
        }

        &--dark {
          color: #262626;
        
          .link-holder__link--icon {
            fill: #262626;
          }

          &:hover {
            border-bottom: 1px solid #262626;
          }
        }
      }
    }
  }
}

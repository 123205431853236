.animation-slide-in {
  opacity: 0;
  transform: translateY(50px);
  animation: slideIn .5s ease forwards;
  animation-delay: .75s;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animation-scale {
  opacity: 0;
  animation: scale 0.75s ease forwards;
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(0.5)
  }

  100% {
    opacity: 1;
    transform: scale(1)
  }
}

.animation-fade-in {
  opacity: 0;
  animation: fadeIn 0.75s ease forwards;
  animation-delay: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
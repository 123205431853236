/* Main colors */
$color-koios-blue: #2f12dc;
$color-koios-pink: #ff0bac;
$color-koios-dark-grey: #424242;
$color-text: #262626;
$color-offwhite: #f2f2f2;

/* Secondairy colors */
$koios-green--hover:      #20ffb1;

/* Gradients */
$gradient-koios: linear-gradient(to right, $color-koios-pink, $color-koios-blue);
$gradient-green-to-pink: linear-gradient(to right, #8FFFB5, #EBDC55, #FF0BAC);

/* Course colors */
$course-blockchain: linear-gradient(to right, $color-koios-pink, $color-koios-blue);
$course-trading: linear-gradient(to right, #12c2e9, #f64f59);
$course-data-science: linear-gradient(to right, #43C6AC, #191654);

/* Button shadow */
$button-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15);
$button-shadow-hover: -2px 2px 4px rgba(0, 0, 0, 0.3);

/* Button active */
$button-active-color: #fafafa;


/* Screen size definitions */
$mobile: 320px;
$mobile-large: 427px;
$tablet: 480px;
$desktop: 769px;

/* Spacings */
$spacing-1x: 8px;
$spacing-2x: 16px;
$spacing-3x: 24px;
$spacing-4x: 32px;
$spacing-5x: 40px;
$spacing-6x: 48px;
$spacing-7x: 56px;
$spacing-8x: 64px;
$spacing-9x: 72px;
$spacing-10x: 80px;

/* Fonts */
$font-family-headings: Righteous, Arial, sans-serif;
$font-family-base: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 6em auto 0;
  max-width: 1080px;
  @include for-size($mobile, $desktop) {
    padding: 0 2rem;
  }
  &__card {
    display: flex;
    flex-direction: column;
    width: 19em;
    padding: 1em;
    border-radius: 20px;
    box-shadow: 0px 0px 21px -10px rgba(110,110,110,0.5);
    transition: all 0.2s ease;
    margin-bottom: 2em;
    background-color: white;
    @include for-size($mobile, $desktop) {
      width: 20em;
    }
    &:hover {
      box-shadow: 0px 0px 21px -10px rgba(110,110,110,1);
      transform: translateY(-0.5em);
    }
    img {
      width: 10em;
      height: 10em;
      margin: auto;
    }
    h2 {
      margin: 1rem 0;
    }
    p {
      text-align: left;
      margin-bottom: 3em;
      font-family: "Source Sans Pro", sans-serif;
      min-height: 6em;
    }
    a {
      text-decoration: none;
      padding: 0.35em 3em;
      border-radius: 11px;
      box-shadow: $button-shadow;
      background: $gradient-koios;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      transition: all 0.2s ease;
      margin: auto;
      align-self: flex-end;
      &:hover {
        box-shadow: $button-shadow-hover;
      }
      &:active {
        background-color: $button-active-color !important;
      }
    }
  }
}
